<template>
  <div class="s-layout">
    <div class="s-container">
      <p class="s-text-xl" v-if="!initialized">Initializing...</p>
      <router-view v-else />
    </div>
    <Alert ref="alert" />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import currency from '@/mixins/liff/currency'
  import Alert from '@/components/Page/Liff/Shared/Alert'

  export default {
    mixins: [currency],
    components: { Alert },
    provide () {
      return {
        $alert: (message, title = '') => this.$refs.alert.show(message, title),
        $user: () => this.user,
      }
    },
    computed: {
      ...mapState({
        initialized: (state) => state.liffOrder.initialized,
      }),
      ...mapGetters({
        user: 'liffOrder/user',
      }),
    },
  }
</script>

<style>
  @import "../../../../assets/css/liff/main.css";

  .s-layout,
  .s-layout *,
  .s-layout *:before,
  .s-layout *:after {
    --s-primary: #e95383, #f6c65a;
    --s-secondary: #de006f;
    --s-warning: #ed6c00;
    --s-danger: #fe0000;
    --s-gray-darker: #363636;
    --s-gray-dark: #595959;
    --s-gray: #909399;
    --s-gray-light: #c0c4cc;
    --s-gray-lighter: #ecedef;
  }

  .s-layout {
    color: var(--s-gray-dark);
    background-color: var(--s-white);
  }

  .s-container {
    padding: 1.5rem 1rem;
  }
</style>
